import * as React from 'react';
import { Box } from '@mui/system';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Button from '@mui/material/Button';
import Select from 'react-select'
import axios from 'axios';
import { InputAdornment } from '@mui/material';
import Cotizacion from './Cotizacion';
import JsPDF from 'jspdf';

const client = axios.create({
    baseURL: 'https://hinoback.compile-studio.com/api/'
    // baseURL: 'http://localhost:1337/api/'
});

const generatePDF = () => {

    const report = new JsPDF('portrait', 'pt', 'a2');
    report.html(document.querySelector('#mainCotizacion')).then(() => {
        report.save('cotizacion.pdf');
    });
}
const Form = () => {
    const [value, setValue] = React.useState(new Date());
    const [marcas, setMarcas] = React.useState(null);
    const [marca, setMarca] = React.useState(null);
    const [lineas, setLineas] = React.useState([{ id: -1, Nombre: 'Seleccione una marca' }]);
    const [linea, setLinea] = React.useState(null);
    const [state, setState] = React.useState({
        nombre_cliente: '',
        empresa: '',
        codigo_vendedor: '',
        asesor: 'Juan Perez',
        Tonelaje: '',
        Largo_Chasis: '',
        tipoCarroceria: '',
        Anio: '',
        Plazo_maximo: '',
        Minimo_Enganche: '',
        Taza_Interes: '',
        Taza_seguro: '',
        precioLista: 0,
        descuento: 0,
        precioPromocion: 0,
        total: 0,
        precioCarroceria: 0,
        enganchePorcentaje: 0,
        engancheMonto: 0,
        placas_otros: 0,
        placas: 0,
        adiestramiento: 0,
        grupo_zeta: 0
    });

    const [anios, setAnios] = React.useState([
        { value: 1, label: '1 Año' },
        { value: 2, label: '2 Años' },
        { value: 3, label: '3 Años' },
    ]);

    const numFormat = (num) => {
        return num.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

    const handleChange = (evt) => {
        let value = evt.target.value;
        let descuento = state.descuento;
        let total = state.total;
        let enganchePorcentaje = state.enganchePorcentaje;
        let engancheMonto = state.engancheMonto;
        if (evt.target.name === 'precioLista') {
            descuento = calcDescuento(value, state.precioPromocion || 0);
            total = calcTotal(state.precioPromocion, value, state.precioCarroceria);
        }
        if (evt.target.name === 'precioPromocion') {
            descuento = calcDescuento(state.precioLista || 0, value);
            total = calcTotal(value, state.precioLista, state.precioCarroceria);
        }
        if (evt.target.name === 'precioCarroceria') {
            total = calcTotal(state.precioPromocion, state.precioLista, value);
        }
        if (
            evt.target.name === 'precioLista'
            || evt.target.name === 'precioPromocion'
            || evt.target.name === 'precioCarroceria'
            || evt.target.name === 'engancheMonto'
        ) {
            value = numFormat(value);
        }
        if (evt.target.name === 'engancheMonto') {
            const r = calcEnganche(state.enganchePorcentaje, value, 1);
            enganchePorcentaje = r.porcentaje;
            engancheMonto = r.monto;
        }
        if (evt.target.name === 'enganchePorcentaje') {
            const r = calcEnganche(value, state.engancheMonto, 2);
            enganchePorcentaje = r.porcentaje;
            engancheMonto = r.monto;
        }

        if (evt.target.name !== 'engancheMonto' && evt.target.name !== 'enganchePorcentaje') {
            const r = calcEnganche(state.enganchePorcentaje, state.engancheMonto, 2);
            enganchePorcentaje = r.porcentaje;
            engancheMonto = r.monto;
        }

        setState({
            ...state,
            [evt.target.name]: value,
            descuento,
            total,
            enganchePorcentaje,
            engancheMonto
        });

    }

    const calcEnganche = (enganchePorcentaje, engancheMonto, tipo) => {
        let monto = parseFloat(engancheMonto);
        let porcentaje = parseFloat(enganchePorcentaje);
        if (tipo === 1) {
            if (state.total !== 0) {
                porcentaje = (monto * 100 / state.total).toFixed(2);
            } else {
                porcentaje = 0;
            }
        }
        if (tipo === 2) {
            if (state.total !== 0) {
                monto = (state.total * (porcentaje / 100)).toFixed(2);
            } else {
                monto = 0;
            }
        }

        let ret = {
            monto,
            porcentaje
        };

        return ret;
    }

    const calcTotal = (precioPromocion, precioLista, precioCarroceria) => {
        if (isNaN(precioPromocion) || precioPromocion === '') {
            precioPromocion = 0;
        }
        if (isNaN(precioLista) || precioLista === '') {
            precioLista = 0;
        }
        if (isNaN(precioCarroceria) || precioCarroceria === '') {
            precioCarroceria = 0;
        }
        let ret = parseFloat(precioPromocion) <= 0 ?
            parseFloat(precioLista) + parseFloat(precioCarroceria) :
            parseFloat(precioPromocion) + parseFloat(precioCarroceria);

        return ret;
    }

    const calcDescuento = (precioLista, precioPromocion) => {
        let ret = parseFloat(precioLista) - parseFloat(precioPromocion);
        return isNaN(ret) ? 0 : ret;
    }

    const handleChangeDate = (newValue) => {
        console.log(newValue);
        setValue(newValue);
    };

    React.useEffect(() => {
        client.get('/marcas').then((response) => {
            setMarcas(response.data.data);
        });
    }, []);

    // Without marcas we can do anything
    if (!marcas) return null;

    return (
        <React.Fragment>
            <Box
                sx={{
                    mb: 2
                }}
                component='form'
            >
                <h1>
                    Ingrese información para la cotización
                </h1>
                <form
                    id="formId">
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                    >
                        <DesktopDatePicker
                            label='Fecha'
                            inputFormat='MM/dd/yyyy'
                            value={value}
                            onChange={handleChangeDate}
                            renderInput={(params) => <TextField {...params} sx={{ my: 2, width: '48%', mr: '4%' }} />}
                        />
                    </LocalizationProvider>
                    <TextField
                        fullWidth
                        label='Nombre del Cliente'
                        variant='outlined'
                        name="nombre_cliente"
                        value={state.nombre_cliente}
                        onChange={handleChange}
                        sx={{
                            my: 2,
                            width: '48%'
                        }}
                        required
                    />
                    <TextField
                        fullWidth
                        id='outlined-basic'
                        label='Empresa'
                        variant='outlined'
                        name="empresa"
                        value={state.empresa}
                        onChange={handleChange}
                        sx={{
                            mb: 2
                        }}
                        required
                    />
                    <h3>
                        Información del vehículo
                    </h3>
                    <Select
                        options={marcas}
                        placeholder='Seleccione una marca'
                        isClearable={true}
                        required
                        getOptionLabel={(option) => option.attributes.Nombre}
                        getOptionValue={(option) => option.id}
                        styles={
                            {
                                control: (base) => ({
                                    ...base,
                                    marginBottom: '16px',
                                    zIndex: '1',
                                    background: 'white',
                                    width: '48%',
                                    marginRight: '4%',
                                    float: 'left',
                                    padding: '8.5px 14px'
                                }),
                                menu: (base) => ({
                                    ...base,
                                    zIndex: '10',
                                    background: 'white',
                                    width: '48%',
                                }),
                            }
                        }
                        onChange={
                            (e) => {
                                setMarca(e);
                                setState({
                                    ...state,
                                    // placas_otros:
                                    //     round_number((
                                    //         parseFloat(e.attributes.placas) +
                                    //         parseFloat(e.attributes.adiestramiento) +
                                    //         parseFloat(e.attributes.grupo_zeta) +
                                    //         parseFloat(state.precioPromocion * 1.25 / 100)
                                    //     ) / 12, 2)
                                    // placas: parseFloat(e.attributes.placas),
                                    adiestramiento: parseFloat(e.attributes.adiestramiento),
                                    grupo_zeta: parseFloat(e.attributes.grupo_zeta)
                                });
                                client.get(`/lineas?populate=*`).then((response) => {
                                    setLineas(response.data.data);
                                });
                            }
                        }
                    />
                    <Select
                        options={lineas}
                        placeholder='Seleccione una linea'
                        isClearable={true}
                        getOptionLabel={(option) => option.attributes.Nombre}
                        getOptionValue={(option) => option.id}
                        styles={
                            {
                                control: (base) => ({
                                    ...base,
                                    marginBottom: '16px',
                                    zIndex: '1',
                                    background: 'white',
                                    width: '48%',
                                    float: 'left',
                                    padding: '8.5px 14px'
                                }),
                                menu: (base) => ({
                                    ...base,
                                    zIndex: '10',
                                    background: 'white',
                                    width: '48%',
                                    right: '0',
                                }),
                            }
                        }
                        onChange={
                            (e) => {
                                console.log(e);
                                setLinea(e);
                                let pmax = e.attributes.plazo_maximo ? e.attributes.plazo_maximo : marca.attributes.Plazo_maximo || 0;
                                setState({
                                    ...state,
                                    Tonelaje: e.attributes.Tonelaje,
                                    Largo_Chasis: e.attributes.Largo_Chasis,
                                    tipoCarroceria: e.attributes.TipoCarroceria,
                                    Plazo_maximo: pmax,
                                    Minimo_Enganche: e.attributes.minimo_enganche ? e.attributes.minimo_enganche : marca.attributes.Minimo_Enganche || '',
                                    Taza_Interes: e.attributes.taza_interes ? e.attributes.taza_interes : marca.attributes.Taza_Interes,
                                    Taza_seguro: e.attributes.taza_seguro ? e.attributes.taza_seguro : marca.attributes.Taza_seguro
                                });

                                // We update the anios to the database data
                                let anios = [];
                                anios.push({ value: 1, label: '1 Año' });
                                for (let i = 1; i < pmax; i++) {
                                    anios.push({ value: i + 1, label: i + 1 + ' Años' });
                                }
                                setAnios(anios);
                            }
                        }
                    />
                    <TextField
                        fullWidth
                        id='outlined-basic'
                        label='Año'
                        variant='outlined'
                        value={state.Anio}
                        name='Anio'
                        type={'number'}
                        onChange={handleChange}
                        sx={{
                            mb: 2,
                            width: '48%',
                            mr: '4%'
                        }}
                    />
                    <TextField
                        fullWidth
                        id='outlined-basic'
                        label='Tonelaje'
                        variant='outlined'
                        value={state.Tonelaje}
                        disabled={true}
                        sx={{
                            mb: 2,
                            width: '48%'
                        }}
                    />
                    <TextField
                        fullWidth
                        id='outlined-basic'
                        label='Largo chasis'
                        variant='outlined'
                        value={state.Largo_Chasis}
                        disabled={true}
                        sx={{
                            mb: 2,
                            width: '48%',
                            mr: '4%'
                        }}
                    />
                    <TextField
                        fullWidth
                        id='outlined-basic'
                        label='Tipo de carrocería'
                        variant='outlined'
                        value={state.tipoCarroceria}
                        name='tipoCarroceria'
                        disabled={true}
                        sx={{
                            mb: 2,
                            width: '48%',
                        }}
                    />

                    <TextField
                        fullWidth
                        id='outlined-basic'
                        label='Asesor [Viene del usuario logueado]'
                        variant='outlined'
                        name='asesor'
                        value={state.asesor}
                        onChange={handleChange}
                        disabled={true}
                        sx={{
                            mb: 2,
                            width: '48%',
                            mr: '4%'
                        }}
                    />

                    <TextField
                        fullWidth
                        id='outlined-basic'
                        label='Placas'
                        variant='outlined'
                        name='placas'
                        value={state.placas}
                        onChange={handleChange}
                        sx={{
                            mb: 2,
                            width: '48%'
                        }}
                    />
                    <h3>
                        Información del crédito
                    </h3>
                    <TextField
                        fullWidth
                        id='outlined-basic'
                        label='Precio de Lista'
                        variant='outlined'
                        name='precioLista'
                        type={'number'}
                        value={state.precioLista}
                        onChange={handleChange}
                        sx={{
                            mb: 2,
                            width: '48%',
                            mr: '4%',
                            float: 'left'
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">Q</InputAdornment>,
                        }}
                    />
                    <Select
                        options={anios}
                        placeholder='Plazo (años)'
                        styles={
                            {
                                control: (base) => ({
                                    ...base,
                                    marginBottom: '16px',
                                    zIndex: '1',
                                    background: 'white',
                                    width: '48%',
                                    float: 'left',
                                    padding: '8.5px 14px'
                                }),
                                menu: (base) => ({
                                    ...base,
                                    zIndex: '10',
                                    background: 'white',
                                    width: '48%',
                                    right: '0',
                                }),
                            }
                        }
                        onChange={
                            (e) => {
                                setState({
                                    ...state,
                                    anios: e.value
                                });
                            }
                        }
                    />
                    <TextField
                        fullWidth
                        id='outlined-basic'
                        label='Precio de Promoción'
                        variant='outlined'
                        name='precioPromocion'
                        type={'number'}
                        value={state.precioPromocion}
                        onChange={handleChange}
                        sx={{
                            mb: 2,
                            width: '48%',
                            mr: '4%',
                            float: 'left'
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">Q</InputAdornment>,
                        }}
                    />

                    <TextField
                        fullWidth
                        id='outlined-basic'
                        label='Enganche Porcenaje'
                        variant='outlined'
                        name='enganchePorcentaje'
                        type={'number'}
                        value={state.enganchePorcentaje}
                        onChange={handleChange}
                        error={state.enganchePorcentaje < state.Minimo_Enganche}
                        sx={{
                            mb: 2,
                            width: '48%',
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                    />

                    <TextField
                        fullWidth
                        id='outlined-basic'
                        label='Precio carrocería'
                        variant='outlined'
                        name='precioCarroceria'
                        type={'number'}
                        value={state.precioCarroceria}
                        onChange={handleChange}
                        sx={{
                            mb: 2,
                            width: '48%',
                            mr: '4%'
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">Q</InputAdornment>,
                        }}
                    />
                    <TextField
                        fullWidth
                        id='outlined-basic'
                        label='Enganche Monto'
                        variant='outlined'
                        name='engancheMonto'
                        type={'number'}
                        value={state.engancheMonto}
                        onChange={handleChange}
                        error={state.enganchePorcentaje < state.Minimo_Enganche}
                        sx={{
                            mb: 2,
                            width: '48%',
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">Q</InputAdornment>,
                        }}
                    />

                    <TextField
                        fullWidth
                        id='outlined-basic'
                        label='Total'
                        variant="filled"
                        disabled
                        value={numFormat(state.total)}
                        name='total'
                        sx={{
                            mb: 2,
                            width: '48%',
                            mr: '4%'
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">Q</InputAdornment>,
                        }}
                    />

                    <TextField
                        fullWidth
                        label='Descuento'
                        variant="outlined"
                        disabled
                        value={numFormat(state.descuento)}
                        onChange={handleChange}
                        sx={{
                            mb: 2,
                            width: '48%',
                        }}
                    />


                    <hr style={{ marginBottom: '50px' }} />
                    <TextField
                        fullWidth
                        id='outlined-basic'
                        label='Máximo plazo años'
                        variant='outlined'
                        name='Plazo_maximo'
                        value={state.Plazo_maximo}
                        onChange={handleChangeDate}
                        sx={{
                            mb: 2,
                            width: '48%',
                            mr: '4%'
                        }}
                    />
                    <TextField
                        fullWidth
                        id='outlined-basic'
                        label='Minimo Enganche %'
                        variant='outlined'
                        value={state.Minimo_Enganche}
                        name='Minimo_Enganche'
                        sx={{
                            mb: 2,
                            width: '48%',
                        }}
                    />
                    <TextField
                        fullWidth
                        id='outlined-basic'
                        label='Taza de Interes %'
                        variant='outlined'
                        value={state.Taza_Interes}
                        sx={{
                            mb: 2,
                            width: '48%',
                            mr: '4%'
                        }}
                    />
                    <TextField
                        fullWidth
                        id='outlined-basic'
                        label='Taza de Seguro %'
                        variant='outlined'
                        value={state.Taza_seguro}
                        sx={{
                            mb: 2,
                            width: '48%',
                        }}
                    />
                    <Button
                        variant='contained'
                        fullWidth
                        size='large'
                        type='button'
                    >
                        Generar cotización
                    </Button>
                </form>
            </Box>
            <Button
                variant='contained'
                fullWidth
                size='large'
                type='submit'
                color='secondary'
                onClick={generatePDF}
            >
                Descargar PDF
            </Button>
            <Cotizacion
                marca={marca}
                fecha={value}
                linea={linea}
                info={state}
            />
        </React.Fragment>
    );
}

export default Form;
