import logo from '../img/logo_hino.jpg';
import logoH from '../img/logo.png';
import codaca from '../img/codaca.png';
const Cotizacion = (props) => {
    const numFormat = (num) => {
        return num.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }

    const formatDate = (date) => {
        return [
            padTo2Digits(date.getDate()),
            padTo2Digits(date.getMonth() + 1),
            date.getFullYear(),
        ].join('/');
    }

    const round_number = (number, decimal_places) => {
        const places = 10 ** decimal_places;
        const res = Math.round(number * places) / places;
        return (res)
    }

    const PMT = (rate, nper, pv, fv, type) => {
        /*
         * rate   - interest rate per month
         * nper   - number of periods (months)
         * pv   - present value
         * fv   - future value
         * type - when the payments are due:
         *        0: end of the period, e.g. end of month (default)
         *        1: beginning of period
         */
        let pmt, pvif;

        fv || (fv = 0);
        type || (type = 0);

        if (rate === 0) {
            return -(pv + fv) / nper;
        }

        pvif = Math.pow(1 + rate, nper);
        pmt = - rate * (pv * pvif + fv) / (pvif - 1);

        if (type === 1)
            pmt /= (1 + rate);
        return pmt;
    }

    let detalle_anios = [];
    for (let i = 0; i < props.info.anios; i++) {
        let pmt = PMT(
            parseFloat(props.info.Taza_Interes) / 100 / 12,
            parseFloat(props.info.anios) * 12,
            parseFloat(props.info.total) - parseFloat(props.info.engancheMonto),
            - parseFloat(props.info.total) * (parseFloat(props.marca.attributes.opcion_compra) / 100),
            0
        );

        let mult = 1;
        if (i > 0) {
            for (let k = 0; k < i; k++) {
                mult *= 0.9;
            }
        }

        let tasaSeguro = props.info.Taza_seguro;
        if (i > 0) {
            tasaSeguro = 6;
        }
        let seguro = (
            (
                (props.info.total * mult) *
                tasaSeguro
                / 100
            ) / 12
        ) +
            parseFloat(props.marca.attributes.GPS);

        let hr = round_number(
            (
                parseFloat(props.info.placas) +
                parseFloat(props.info.adiestramiento) +
                parseFloat(props.info.grupo_zeta) +
                parseFloat(parseFloat(props.info.precioPromocion) * 1.25 / 100)
            ) / 12, 2)
        let subtotal = -pmt + seguro + parseFloat(hr);
        let iva = subtotal * 0.12;
        let total = subtotal + iva;

        for (let j = 0; j < 12; j++) {
            detalle_anios.push
                (
                    <tr key={`linea_presupuesto_${i}_${j}`}>
                        <td>
                            {i * 12 + j + 1}
                        </td>
                        <td>
                            {numFormat(-pmt.toFixed(2))}
                        </td>
                        <td>
                            {
                                hr
                            }

                        </td>
                        <td>
                            {
                                numFormat(seguro)
                            }
                        </td>
                        <td>
                            {
                                numFormat(subtotal)
                            }
                        </td>
                        <td>
                            {
                                numFormat(iva)
                            }
                        </td>
                        <td>
                            {
                                numFormat(total)
                            }
                        </td>
                    </tr>
                );
        }
    }

    const middleIndex = Math.ceil(detalle_anios.length / 2);
    const firstHalf = detalle_anios.splice(0, middleIndex);
    const secondHalf = detalle_anios.splice(-middleIndex);

    let gastos_iniciales = parseFloat(props.info.total) * 0.02;
    let placas = props.info ? parseFloat(props.info.placas) : 0;
    let opcion_compra = props.marca !== null ? props.marca.attributes.opcion_compra : 0;

    let descuento = props.info ? parseFloat(props.info.descuento) : 0;

    return (
        <div id="mainCotizacion">
            <div className="cotizacion">
                <div className="client">
                    <div className="left">
                        <div className="line underline">
                            <div className="name">
                                Nombre:
                            </div>
                            <div className="info">
                                {props.info ? props.info.nombre_cliente : ''}
                            </div>
                        </div>
                        <div className="line underline">
                            <div className="name">
                                Empresa:
                            </div>
                            <div className="info">
                                {props.info ? props.info.empresa : ''}
                            </div>
                        </div>
                        <div className="line underline">
                            <div className="name">
                                Dirección:
                            </div>
                            <div className="info">
                                Guatemala
                            </div>
                        </div>
                    </div>
                    <div className="center">
                        <div className="line underline">
                            <div className="name">
                                Código de Vendedor:
                            </div>
                            <div className="info">
                                {props.info ? props.info.codigo_vendedor : ''}
                            </div>
                        </div>
                        <div className="line underline">
                            <div className="name">
                                Fecha:
                            </div>
                            <div className="info">
                                {formatDate(props.fecha)}
                            </div>
                        </div>
                        <div className="line underline">
                            <div className="name">
                                Vendedor:
                            </div>
                            <div className="info">
                                {props.info ? props.info.asesor : ''}
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <img src={codaca} alt="Logo Codaca" />
                    </div>
                </div>
                <div className='header'>
                    En respuesta a su amable solicitud les enviamos la cotización para un contrato de
                    <br />
                    LEASING FINANCIERO con opción a compra:
                    <div className="pais">GUATEMALA</div>
                </div>
                <section className="sub-header">
                    <div className="left">
                        <div className="line underline">
                            <div className="name">
                                Marca:
                            </div>
                            <div className="info">
                                {props.marca ? props.marca.attributes.Nombre : ''}
                            </div>
                        </div>
                        <div className="line underline">
                            <div className="name">
                                Modelo:
                            </div>
                            <div className="info">
                                {props.linea ? props.linea.attributes.Nombre : ''}
                            </div>
                        </div>
                        <div className="line underline">
                            <div className="name">
                                Carrocería:
                            </div>
                            <div className="info">
                                {props.linea ? props.linea.attributes.TipoCarroceria : ''}
                            </div>
                        </div>
                        <div className="line underline">
                            <div className="name">
                                Año:
                            </div>
                            <div className="info">
                                {props.info ? props.info.Anio : ''}
                            </div>
                        </div>
                        <div className="line underline">
                            <div className="name">
                                Capacidad:
                            </div>
                            <div className="info">
                                {props.info ? props.info.Tonelaje : ''} Toneladas
                            </div>
                        </div>
                        <div className="info-pagos">
                            <div className="line underline">
                                <div className="name">
                                    Precio de Lista:
                                </div>
                                <div className="info number">
                                    Q {props.info ? numFormat(parseFloat(props.info.precioLista)) : ''} <span className='small'>(mas iva)</span>
                                </div>
                            </div>
                            <div className="line underline">
                                <div className="name">
                                    Descuentos
                                </div>
                                <div className="info number">
                                    Q {props.info ? numFormat(descuento) : ''} <span className='small'>(mas iva)</span>
                                </div>
                            </div>
                            <div className="line underline">
                                <div className="name">
                                    Precio Especial Chasis
                                </div>
                                <div className="info number">
                                    Q {props.info ? numFormat(parseFloat(props.info.precioLista) - descuento) : ''} <span className='small'>(mas iva)</span>
                                </div>
                            </div>
                            <div className="line double-underline">
                                <div className="name">
                                    Valor de furgón:
                                </div>
                                <div className="info number">
                                    Q {props.info ? numFormat(parseFloat(props.info.precioCarroceria)) : ''} <span className='small'>(mas iva)</span>
                                </div>
                            </div>
                            <div className="line underline">
                                <div className="name">
                                    Total:
                                </div>
                                <div className="info number">
                                    Q {props.info ? numFormat(parseFloat(props.info.total)) : ''} <span className='small'>(mas iva)</span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="right">
                        <div className="logo">
                            <img src={logo} alt='logo' />
                        </div>
                        <div className="plazo">
                            PLAZO DE CONTRATO {props.info.anios} AÑOS
                        </div>
                        <div className="info-pagos">
                            <div className="line">
                                <div className="subtitle">
                                    Primer Pago
                                </div>
                            </div>
                            <div className="line underline">
                                <div className="name">
                                    Anticipo
                                    <div className="percentage">
                                        {props.info ? parseFloat(props.info.enganchePorcentaje).toFixed(2) : ''} %
                                    </div>
                                </div>
                                <div className="info number">
                                    Q {props.info ? numFormat(parseFloat(props.info.engancheMonto)) : ''} <span className='small'>(mas iva)</span>
                                </div>
                            </div>
                            <div className="line underline">
                                <div className="name">
                                    Placas
                                </div>
                                <div className="info number">
                                    Q {numFormat(placas)} <span className='small'>(mas iva)</span>
                                </div>
                            </div>
                            <div className="line double-underline">
                                <div className="name">
                                    Gastos Iniciales
                                </div>
                                <div className="info number">
                                    Q {numFormat(gastos_iniciales)} <span className='small'>(mas iva)</span>
                                </div>
                            </div>
                            <div className="line underline">
                                <div className="name">
                                    Total primer pago
                                    <div className="small">
                                        ** Debe de ser cancelado con cheque de caja.
                                    </div>
                                </div>
                                <div className="info number">
                                    Q {props.info ? numFormat(parseFloat(props.info.engancheMonto) + gastos_iniciales + placas) : ''} <span className='small'>(mas iva)</span>
                                </div>
                            </div>
                            <div className="line underline gray-bg">
                                <div className="name">
                                    Valor a financiar
                                </div>
                                <div className="info number">
                                    Q {props.info ? numFormat(parseFloat(props.info.total) - parseFloat(props.info.engancheMonto)) : ''} <span className='small'>(mas iva)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="table">
                    <table className='payments' cellSpacing="0" cellPadding="0">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Capital+ interes </th>
                                <th>Servicios Hr </th>
                                <th>seguro </th>
                                <th>Subtotal </th>
                                <th>Iva </th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                firstHalf
                            }
                        </tbody>
                    </table>
                    <table className='payments' cellSpacing="0" cellPadding="0">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Capital+ interes </th>
                                <th>Servicios Hr </th>
                                <th>seguro </th>
                                <th>Subtotal </th>
                                <th>Iva </th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                secondHalf
                            }
                        </tbody>
                    </table>
                </section>
                <section className="footer">
                    <div className="smallone">
                        <div className="opcion">
                            Opción a compra:  Q{props.marca !== null ? numFormat(parseFloat(props.marca.attributes.opcion_compra)) : numFormat(0)}
                            <span className="small">
                                (mas gastos de traspaso e impuestos)
                            </span>
                        </div>
                    </div>
                    <div className="smalltwo">
                        <div className="left">
                            Incluye seguro y gps
                        </div>
                        <div className="right">
                            Cotización  Valida por 10 días.
                        </div>
                    </div>
                </section>
            </div>
            <div className="underFooter">
                <div className="nota">
                    <div className="left">
                        NOTA:  Esta cotización queda sujeta a aprobación del departamento de créditos de Hinorent, S.A.
                    </div>
                    <div className="right">
                        Valores por unidad
                    </div>
                </div>
                <div className="subNote">
                    LA CUOTA MENSUAL DE LEASING FINANCIERO INCLUYE:
                    <ul>
                        <li>Incluye seguro y GPS  </li>
                        <li>GPS para monitoreo y recuperación en caso de robo.</li>
                        <li>Asistencia jurídica en caso de accidente.</li>
                        <li>Impuesto de circulación anual.</li>
                        <li>Capacitación anual para pilotos.</li>
                    </ul>
                </div>
                <div className="important">
                    IMPORTANTE:
                    <ol>
                        <li>El contrato es forzoso durante el plazo pactado por ambas partes, con el propósito de comprar el vehículo al final del plazo.  Si se anulara el contrato anticipadamente por parte del arrendatario la penalización son 3 cuotas normales por cada año que no se cumpla con el plazo pactado.</li>
                        <li>La renta es pagadera al final de cada mes.</li>
                        <li>Todos los gastos de mantenimiento reparación y uso corren por cuenta del arrendatario.</li>
                        <li>La renta mensual está sujeta a la variación que pudiera haber en la tasa de interés bancaria y los cambios de índole fiscal que afectan a un arrendatario.</li>
                        <li>El primer pago debe ser cancelado con cheque de caja a nombre de Hinorent, S.A.  O efectuar el deposito 3 días antes de  la entrega de la unidad.</li>
                    </ol>
                    Nos agradaría de mayor manera que considerara a Hinorent, S.A.  como la compañía de apoyo para sus necesidades.<br />
                    Esperando que la presente propuesta diseñada específicamente para cubrir sus necesidades de transporte comercial, le sea valiosa en el proceso de analizar la mejor opción para su Empresa.<br />
                    Quedamos a la espera de sus prontas y agradables noticias.<br /><br />
                    Atentamente,
                </div>
                <div className="signature">
                    <div className="left">
                        Ing. Jorge Gutiérrez<br />
                        Gerente de Ventas<br />
                        Hinorent, S.A. <br />
                    </div>
                    <div className="right">
                        <div className="line">
                            Firma Autorización del Cliente
                        </div>
                    </div>
                </div>
                <div className="lastFooter">
                    <div className="logo">
                        <img src={logoH} alt='logo' />
                    </div>
                    <div className="right">
                        Calzada San Juan 11-09, Zona 7, Guatemala, C.A. PBX: (502) 2440-9400<br />
                        4219-2035 / Siguenos en nuestras redes: HINORENT
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Cotizacion;
