import logo from '../img/logo.png';
import { Link } from "react-router-dom";
const Header = () => {
    return (
        <header>
            <div className='logo'>
                <img src={logo} alt='logo' />
            </div>
            <div className='title'>
                Cotizador de Vehículos
            </div>
            <Link to="/" className='sign-out'>
                Salir
            </Link>
        </header>
    );
}

export default Header;
